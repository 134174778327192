import React from "react";
import { FaBalanceScaleLeft, FaHandshake, FaThumbsUp } from "react-icons/fa";

const BlockValuesHome = () =>{

    const values = [
        {
            tittle: 'Integrity',
            text: 'We are more than just painting services. Our best practices come from our virtudes and values we provide to our customers.',
            icon: <FaBalanceScaleLeft fontSize={70} className='titleColorFt' />
        },
        {
            tittle: 'Honesty',
            text: 'We operate on utmost transperency and no hidden charges. It is all in front of you with safety assurance.',
            icon: <FaThumbsUp fontSize={60} className='titleColorFt' />
        },
        {
            tittle: 'Customer Service',
            text: "We strive to make our customers happy and satisfied even after the project's completion.",
            icon: <FaHandshake fontSize={70} className='titleColorFt' />
        }
    ]

    return(
        <section className="w-[95%] py-10 px-3 flex justify-center items-center gap-10 mx-auto flex-wrap">
            {
                values.map((item, index) =>{
                    return(
                        <articule key={index} className="bg-white flex flex-col border-t-2 border-red-400 items-center justify-center max-w-[320px] min-h-[300px] px-2 py-5 shadow-2xl rounded-md">
                            {item.icon}
                            <h5>{item.tittle}</h5>
                            <p className="text-center">{item.text}</p>
                        </articule>
                    )
                })
            }
        </section>
    )
}

export default BlockValuesHome